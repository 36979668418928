import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"
import * as Transition from "el-transition"

export default class extends Controller {
  static targets = [
    "modal",
    "backdrop",
  ]

  connect() {
    document.addEventListener("keyup", this.keyup)

    useClickOutside(this, {
      element: this.modalTarget,
    })

    if (this.element.classList.contains('hidden')) {
      this.enter()
    }
  }

  disconnect() {
    document.removeEventListener("keyup", this.keyup)
  }

  clickOutside() {
    this.close()
  }

  async close() {
    await Promise.all([
      Transition.leave(this.modalTarget),
      Transition.leave(this.backdropTarget),
    ])
      
    this.element.remove()
  }

  enter() {
    console.log("enter")
    this.element.classList.remove('hidden')

    Transition.enter(this.modalTarget)
    Transition.enter(this.backdropTarget)
  }

  keyup = (event) => {
    if (event.key == "Escape") {
      this.close()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    reportId: String,
    embedUrl: String,
    accessToken: String
  }

  async connect() {

    const pbi = await import('powerbi-client')


    const config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      id: this.reportIdValue,
      embedUrl: this.embedUrlValue,
      accessToken: this.accessTokenValue,
    }

    window.powerbi.embed(this.element, config)
  }
}

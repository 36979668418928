import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "review",
  ]

  connect() {
    this.timeout = setTimeout(this.next, 10000)

    this.next()
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  next = () => {
    const index = this.reviewTargets.findIndex(this.isVisible)

    this.reviewTargets[index]?.classList.add("hidden")
    this.reviewTargets[index + 1]?.classList.remove("hidden")
  }

  isVisible = (element) => {
    return !element.classList.contains("hidden")
  }
}

import { registerControllers } from 'stimulus-vite-helpers'
import { application } from "~/controllers/application"
import Sortable from '@stimulus-components/sortable'
import Clipboard from '@stimulus-components/clipboard'
import Combobox from '@josefarias/hotwire_combobox'

registerControllers(application, import.meta.glob('./**/*_controller.js', {
  eager: true,
}))

application.register("sortable", Sortable)
application.register("clipboard", Clipboard)
application.register("hw-combobox", Combobox)
